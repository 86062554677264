import React from 'react';
import './SkillOption.css';

function SkillOption({ name, img }) {

    const [ hover, setHover ] = React.useState(false);

    const namee = hover ? name : '';

    return (        
        <div className="skillOption__container" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <div>
                <img src={ img } alt={ name } />
            </div>
            <div style={{ height: '10px'}}>
                <p style={{ color: 'black', fontSize: '0.6rem', position: 'relative', zIndex: '1'}}>{ namee}</p>
            </div>
        </div>        
    )
}

export default SkillOption
