import React from 'react';
import './WorkItems.css'

function WorkItems({ title, description, image, link, linkDesc }) {

    const [ hover, setHover ] = React.useState(false);
    return (
        <div className="workitems__container" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            { !hover && (<img className="workitems__image" alt="airbnb" src={image}/>  )}
            { hover && (<div className="hover__div">
                <h3>{title}</h3>
                <p>{description}</p>
                <a href={link} alt={title} target="_blank" rel="noreferrer"><p>{ linkDesc }</p></a>
            </div> )}
        </div>
    )
}

export default WorkItems
