import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SideBar from './components/SideBar';
import About from './components/About';
import Timeline from './components/Timeline';
import Skills from './components/Skills';
import Social from './components/Social';
import Work from './components/Work';

function App() {
  return (
    <div className="app">
      <Router>        
        <main>
          <section className="main__container">
            <div className="siderbar__container">
              <SideBar />
            </div>
            
            <div className="container">
              <Switch>
                <Route exact path="/" component={About} />
                <Route path="/about" component={About} />
                <Route path="/timeline" component={Timeline} />
                <Route path="/skills" component={Skills} />
                <Route path="/social" component={Social} />
                <Route path="/work" component={Work} />
              </Switch>
              
            </div>
          </section>
        </main>
        <div className="circle1"></div>
        <div className="circle2"></div>
      </Router>
    </div>
  );
}

export default App;
