import React from 'react';
import WorkItems from './WorkItems';
import "./Work.css";

function Work() {
    return (
        <div className="work__container">
            <WorkItems 
                title="AirBnb Clone" 
                description = "AirBnb Clone website developed using React js and tailwind css"
                image="/images/airbnb.PNG"
                link="https://airbnb.thedineshbabu.com/"
                linkDesc="Visit Site"
            />
            <WorkItems 
                title="Gmail Clone" 
                description = "GMail Clone website developed using React js, Redux and Firebase"
                image="/images/gmail.PNG"
                link="https://gmail.thedineshbabu.com/"
                linkDesc="Visit Site"
            />
            <WorkItems 
                title="Signal Clone" 
                description = "Signal Clone mobile app developed using React Native, Redux and Firebase"
                image="/images/signal.PNG"
                link="https://github.com/thedineshbabu/signal-clone"
                linkDesc="Source"
            />
            <WorkItems 
                title="Portfolio Site" 
                description = "Portfolio Site developed using React Js and CSS"
                image="/images/portfolio.PNG"
                link="https://portfolio.thedineshbabu.com/"
                linkDesc="Visit Site"
            />
        </div>
    )
}

export default Work
