import React from 'react'

function Social() {
    return (
        <div>
            <p>Social</p>
        </div>
    )
}

export default Social
