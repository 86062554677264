import React from 'react';
import './SideBarFooter.css';

function SideBarFooter() {
    return (
        <div className="sidebarfooter__container">
            <a href="mailto:me@thedineshbabu.com" target="_blank" rel="noreferrer">
                <img className="sidebarfooter__logo" src="/images/email.png" alt="email" />
            </a>
            <a href="https://www.instagram.com/thedineshbabu/" target="_blank" rel="noreferrer">
                <img className="sidebarfooter__logo" src="/images/insta.png" alt="instagram" />
            </a>
            <a href="https://github.com/thedineshbabu" target="_blank" rel="noreferrer">
                <img className="sidebarfooter__logo" src="/images/github.png" alt="github" />
            </a>
            <a href="https://www.linkedin.com/in/thedineshbabu/" target="_blank" rel="noreferrer">
                <img className="sidebarfooter__logo" src="/images/linkedin.png" alt="linkedin" />
            </a>        
        </div>
    )
}

export default SideBarFooter
