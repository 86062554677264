let timelineElements = [
  {
    id: 1,
    title: "Senior Consultant",
    company: "Korn Ferry",
    location: "Madison, WI",
    date: "October 2021 - present",
    icon: "work",
  },
  {
    id: 2,
    title: "Consultant",
    company: "IFlowsoft Solutions Inc",
    location: "Madison, WI",
    date: "August 2020 - October 2021",
    icon: "work",
  },
  {
    id: 3,
    title: "Senior Associate",
    company: "Cognizant Technology Solutions",
    location: "Madison, WI",
    date: "October 2014 - July 2020",
    icon: "work",
  },
  {
    id: 4,
    title: "Azure Developer Associate",
    company: "Microsoft",
    location: "Madison, WI",
    date: "June 2020",
    icon: "school",
  },
  {
    id: 5,
    title: "Software Analyst",
    company: "Bally Technology",
    location: "Chennai TN, India",
    date: "March 2014 - October 2014",
    icon: "work",
  },
  {
    id: 6,
    title: "Member Technical Staff",
    company: "HCL Technologies",
    location: "Chennai TN, India",
    date: "February 2012 - March 2014",
    icon: "work",
  },
  {
    id: 7,
    title: "Software Engineer",
    company: "Shriram Value Service",
    location: "Chennai TN, India",
    date: "September 2010 - February 2012",
    icon: "work",
  },
  {
    id: 8,
    title: "Bachelor of Technology",
    company: "Pondicherry University",
    location: "Karaikal PY, India",
    date: "2005 - 2009",
    icon: "school",
  },
];

export default timelineElements;
