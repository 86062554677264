import React from 'react';
import SkillOption from './SkillOption';
import './Skills.css';
import skills from './skillElement';


function Skills() {
    return (
        <div className="skills__container">
            {skills.map((skill, index) => (
                <SkillOption key={index} {...skill} />                
            ))}
        </div>
    )
}

export default Skills
