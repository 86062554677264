import React from 'react';
import { useHistory } from 'react-router-dom';

function SideBarOption({ title, path, Icon }) {

    const history = useHistory();
    
    return (
        <div className="link" style={{ cursor: 'pointer', display: 'flex' }} onClick={() => history.push(path)}>
            <Icon />
            <h2 style={{ fontSize: '1rem', flexWrap: "nowrap"}}>{title}</h2>
        </div>
    )
}

export default SideBarOption
