import React from 'react';
import './Timeline.css';
import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./school.svg";

import timelineElements from "./timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function Timeline() {

    let workIconStyles = { background: "#06D6A0" };
    let schoolIconStyles = { background: "#f9c74f" };

    return (
        <div className="timeline__container">
            <VerticalTimeline>
                {timelineElements.map((element) => {
                let isWorkIcon = element.icon === "work";

                return (
                    <VerticalTimelineElement
                    key={element.key}
                    date={element.date}
                    dateClassName="date"
                    iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                    icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                    >
                    <h2 style={{ fontSize: '1rem'}}>
                        {element.title}
                    </h2>
                    <h2 style={{ fontSize: '0.8rem'}}>
                        {element.company}
                    </h2>
                    <h2 style={{ fontSize: '0.75rem'}}>
                        {element.location}
                    </h2>
                    </VerticalTimelineElement>
                );
                })}
            </VerticalTimeline>
        </div>
    )
}

export default Timeline
