import React from 'react';
import './Profile.css';

function Profile() {
    return (
        <div className="profile__container">
            <img className="profileImg" src="/images/avatar.png" alt=""/>
            <h3>Dineshbabu Manoharan</h3>
            <p>Full Stack Developer</p>
        </div>
    )
}

export default Profile
