const skills =[    
    {
        name: "Azure",
        img: "/images/azure.svg"
    },
    {
        name: "Azure Function",
        img: "/images/azfunc.svg"
    },
    {
        name: "AKS",
        img: "/images/aks.svg"
    },
    {
        name: "Azure Devops",
        img: "/images/azure-devops.svg"
    },
    {
        name: "Redis",
        img: "/images/redis.svg"
    },
    {
        name: "SQL",
        img: "/images/sql.svg"
    },
    {
        name: "Bot Service",
        img: "/images/botservices.svg"
    },
    {
        name: "Cognitive Service",
        img: "/images/cognitiveservices.svg"
    },
    {
        name: "Service Bus",
        img: "/images/servicebus.svg"
    },
    {
        name: "Cosmos DB",
        img: "/images/cosmosDB.svg"
    },
    {
        name: "JavaScript",
        img: "/images/javascript.svg"
    },
    {
        name: "Babel",
        img: "/images/babel.svg"
    },
    {
        name: "Bitbucket",
        img: "/images/bitbucket.svg"
    },
    {
        name: "EF Core",
        img: "/images/efcore.png"
    },    
    {
        name: "Dotnet",
        img: "/images/dotnet.svg"
    },
    {
        name: "Dotnet Core",
        img: "/images/dotnetcore.png"
    },
    {
        name: "C#",
        img: "/images/csharp.svg"
    }, 
    {
        name: "React",
        img: "/images/react.svg"
    },
    {
        name: "Vue js",
        img: "/images/vue.svg"
    },
    {
        name: "Angular js",
        img: "/images/angularjs.svg"
    },
    {
        name: "Ionic",
        img: "/images/ionic.svg"
    },
    {
        name: "Typescript",
        img: "/images/typescript.svg"
    },
    {
        name: "Redux",
        img: "/images/redux.svg"
    },
    {
        name: "GraphQL",
        img: "/images/graphql.png"
    },
    {
        name: "Bootstrap",
        img: "/images/bootstrap.svg"
    },
    {
        name: "CSS",
        img: "/images/css.svg"
    },
    {
        name: "Cucumber",
        img: "/images/cucumber.svg"
    },
    {
        name: "Docker",
        img: "/images/docker.svg"
    },
    {
        name: "RabbitMQ",
        img: "/images/rabbitmq.svg"
    },
    {
        name: "VSCode",
        img: "/images/vscode.png"
    },
    {
        name: "Kubernetes",
        img: "/images/kubernets.svg"
    },
    {
        name: "NPM",
        img: "/images/npm.svg"
    },
    {
        name: "Node js",
        img: "/images/node-js.svg"
    },
    {
        name: "Mongo DB",
        img: "/images/mongodb.svg"
    },
    {
        name: "Git",
        img: "/images/git.svg"
    },       
    {
        name: "JQuery",
        img: "/images/jquery.svg"
    },    
    {
        name: "Webpack",
        img: "/images/webpack.svg"
    },
    {
        name: "Application Gateway",
        img: "/images/APIM.svg"
    },
];

export default skills;