import React from 'react';
import './About.css';

function About() {
    return (
        <div className="about__container">
            <p>
                Dinesh is a full stack .NET Developer with over 11 years of experience working with insurance, banking, and gaming companies. He specializes in using .NET Core, Docker, and Kubernetes in building APIs, and in using React and Vue for creating UI applications.
            </p>
            <p>
                Dinesh is a Microsoft certified Azure Developer Associate. He has experience in various Azure Resources, like Azure Functions, AKS, Azure Service Bus, Azure Application Gateway, CosmosDB, App Services, Application Insights, etc. As part of his job, he interacts with various clients and teams to understand their requirements and build solutions for them. He actively participates in various agile ceremonies and has been a member of various agile teams. He involves himself in all phases of the development life cycle, and is a very good communicator. He is a very good team player and is always ready to learn new technologies. Dinesh works on CI/CD pipelines and has experience in various CI/CD tools like Azure DevOps, GitLab, Urban Code Deploy, etc. 
            </p>
            <p>
                In his free time, Dinesh works on various projects, and enjoys learning new technologies and solving problems. His latest crush is on javascript frameworks: he is a fan of React and Vue, and he enjoys working with Node and Express. He plays cricket and racquetball, and loves to watch movies and TV shows.
            </p>
        </div>
    )
}

export default About
