import React from 'react';
import SideBarOption from './SideBarOption';
import Profile from './Profile';
import './SideBar.css';
import SideBarFooter from './SideBarFooter';
import { BsFillPersonFill, BsStarFill, BsTools, BsGear } from 'react-icons/bs';

const sideBarOptions = [
  { title: 'About', path: '/about', Icon: BsFillPersonFill},
  { title: 'Skills', path: '/skills', Icon: BsTools },  
  { title: 'Timeline', path: '/timeline', Icon: BsStarFill },
  { title: 'Recent Work', path: '/work', Icon: BsGear},
  // { title: 'Social', path: '/social', Icon: BsPeople },
];

function SideBar() {
    return (
        <div>
            <Profile />
            <div className="links">
              {sideBarOptions.map(({ title, path, Icon }) => (
                  <SideBarOption key={title} title={title} path={path} Icon={Icon}/>
              ))}
            </div>
            <SideBarFooter />
        </div>
    )
}

export default SideBar
